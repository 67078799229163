import { GetServerSideProps, GetServerSidePropsContext, Page } from "next";
import { PopupTags, SiteContentScopes, TranslationScopes } from "@finbackoffice/enums";
import { HomePageSectionsEnum } from "@finbackoffice/fe-core";
import { withSsrCasinoFavorites } from "@finbackoffice/site-server-core";
import ErrorBoundary from "components/base/error-boundary/ErrorBoundary";
import Seo from "components/base/seo/Seo";
import { getCommonLayout } from "components/layout/common/CommonLayout";
import Home from "components/pages/home/Home";
import { usePrematchSports } from "hooks";
import {
    CommonSsrProps,
    clientBFFSingleton,
    createGetServerSideProps,
    getServerData,
    getServerRuntimeConfig,
    withSsrReels,
} from "services/ssr";
import { getCurrentLocale } from "utils/helpers";
import { IReelsDataState } from "types/reels.data";

type SsrProps = {
    reels: IReelsDataState | null;
};

export const getServerSideProps: GetServerSideProps = createGetServerSideProps<
    SsrProps & CommonSsrProps
>(async (context: GetServerSidePropsContext) => {
    const runtimeConfig = await getServerRuntimeConfig();
    const client = clientBFFSingleton.getInstance(context.req.cookies.jwt);
    const locale = getCurrentLocale(runtimeConfig, context.locale);
    const isReelsWidgetEnabled = runtimeConfig.SITE_CONFIG.layouts.homePage.widgets.find(
        (widget) => widget.name === HomePageSectionsEnum.Reels && widget.enable,
    );

    const [{ translations, configs, profile, popupBanners, banners }, casinoFavoriteGames, reels] =
        await Promise.all([
            getServerData(runtimeConfig, context, client, {
                popupBanners: { device: PopupTags.Mobile },
                translationScope: [
                    TranslationScopes.Home,
                    TranslationScopes.Casino,
                    TranslationScopes.CasinoLiveCasino,
                    TranslationScopes.CasinoSlots,
                    TranslationScopes.CasinoWidgets,
                    TranslationScopes.PreMatch,
                ],
                banners: { scope: [SiteContentScopes.Homepage] },
            }),
            context.req.cookies.jwt ? withSsrCasinoFavorites(client) : undefined,
            isReelsWidgetEnabled
                ? withSsrReels(client, locale, [SiteContentScopes.Homepage])
                : undefined,
        ]);

    return {
        props: {
            configs,
            runtimeConfig,
            jwt: context.req.cookies.jwt || "",
            profile: profile || null,
            locale,
            translations,
            banners: banners.items,
            popupBanners: popupBanners || [],
            casinoFavoriteGames: casinoFavoriteGames?.items || null,
            reels: isReelsWidgetEnabled ? reels || null : null,
        },
    };
});

const IndexPage: Page = ({ reels }: SsrProps) => {
    usePrematchSports();

    return (
        <ErrorBoundary name={Home.name}>
            <Seo page={TranslationScopes.Home} />
            <Home reels={reels} />
        </ErrorBoundary>
    );
};

IndexPage.getLayout = getCommonLayout;

export default IndexPage;
